body {
  background-color: rgba(15,0,129,1);
  color: #fff;
  font-family: Monospace;
  font-weight: bold;
  font-size: 16px;
  padding: 25px 40px;
}

a {
  color: #fff;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 0;
}

a:hover {
  background: rgba(255, 255, 255, 0.25);
}